import Placeholder from './components/Placeholder';
import './App.css';

function App() {
  return (
    <Placeholder />
  );
}

export default App;
