import logo from '../img/logo.png';

function Placeholder() {
    return (
        <section>
            <article>
                <img src={logo} alt="The Word Bride logo" />
                <blockquote>
                    "And I heard another voice from heaven, saying, Come out of her, my
                    people, that ye be not partakers of her sins, and that ye receive not of
                    her plagues."
                    <footer>
                        <cite>—Revelation 18:4</cite>
                    </footer>
                </blockquote>
            </article>
        </section>
    );
}

export default Placeholder;